.bl_login {
  font-size: 1.16667rem;
}

.bl_login .bl_login_result {
  margin-bottom: 20px;
}

.bl_login .bl_login_formParts {
  margin-bottom: 20px;
}

.bl_login .bl_login_txt {
  font-size: 1.33333rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.bl_login .bl_login_txt_small {
  color: #999;
  font-size: 1.16667rem;
  font-weight: normal;
}

.bl_login .bl_login_input {
  margin-top: 10px;
}

.bl_login .bl_login_input input[type=text],
.bl_login .bl_login_input input[type=email],
.bl_login .bl_login_input input[type=password] {
  width: 100%;
  border: 2px solid #d8d8d8;
  border-radius: 0;
  padding: 5px 10px;
}

.bl_login .bl_login_submit {
  margin-top: 20px;
}

.bl_login .bl_login_submit button[type=submit] {
  min-width: 100px;
  min-height: 44px;
}

.bl_login .bl_login_submit button[type=submit]:not(:hover) {
  background-color: transparent;
}

.bl_login .bl_login_error {
  color: #b70000;
  font-weight: 700;
  margin-top: 20px;
}

.bl_login .bl_login_supTxt {
  color: #999;
  font-size: 1rem;
  display: block;
  padding-top: 7px;
}

.bl_login .is_required {
  color: #b70000;
  font-size: 1rem;
}

.bl_lock .bl_lock_txt {
  margin-bottom: 1em;
}

.bl_pwNumber {
  font-size: 1.33333rem;
  border: 1px solid #b70000;
  padding: 10px;
  margin-bottom: 40px;
}

.bl_lock {
  font-size: 1.33333rem;
  border: 1px solid #b70000;
  padding: 10px;
  margin-bottom: 40px;
}

.bl_lock .bl_lock_txt:last-child {
  margin-bottom: 0;
}
