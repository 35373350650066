@mixin fontSize($size, $base: 12) {
  font-size: ($size / $base) * 1rem;
}

.bl_login {
  $wrp: '.bl_login';
  @include fontSize(14);

  #{$wrp}_result {
    margin-bottom: 20px;
  }
  #{$wrp}_formParts {
    margin-bottom: 20px;
  }

  #{$wrp}_txt {
    @include fontSize(16);
    font-weight: bold;
    margin-bottom: 5px;

    &_small {
      color:#999;
      @include fontSize(14);
      font-weight: normal;
    }
  }

  #{$wrp}_input {
    margin-top: 10px;

    input[type=text],
    input[type=email],
    input[type=password] {
      width: 100%;
      border: 2px solid #d8d8d8;
      border-radius: 0;
      padding: 5px 10px;

      // &::placeholder {
      //   color: #c8c8c8;
      //   @include fontSize(12);
      // }
    }
  }

  #{$wrp}_submit {
    margin-top: 20px;

    button[type=submit] {
      min-width: 100px;
      min-height: 44px;

      &:not(:hover) {
        background-color: transparent;
      }
    }
  }
  #{$wrp}_error {
    color:#b70000;
    font-weight: 700;
    margin-top: 20px;
  }
  #{$wrp}_supTxt {
    color:#999;
    @include fontSize(12);
    display: block;
    padding-top: 7px;
  }
  .is_required {
    color:#b70000;
    @include fontSize(12);
  }
}

.bl_lock {
  $wrp: '.bl_lock';

  #{$wrp}_txt {
    margin-bottom: 1em;
  }
}

.bl_pwNumber {
  $wrp: '.bl_pwNumber';
  @include fontSize(16);
  border: 1px solid #b70000;
  padding: 10px;
  margin-bottom: 40px;
}

.bl_lock {
  $wrp: '.bl_lock';
  @include fontSize(16);
  border: 1px solid #b70000;
  padding: 10px;
  margin-bottom: 40px;

  #{$wrp}_txt {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
